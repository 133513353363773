import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { default as config } from "../config.json";
import * as axios from "axios";
export default function Profile() {
  const { username } = useParams();
  const [User, setUser] = useState();

  useEffect(() => {
    async function getUser() {
      await axios
        .get(`${config.api_base_url}/api/user/${username}`)
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "/";
        });
    }
    getUser();
  }, [username]);
  async function addClick(id) {
    await axios
      .post(`${config.api_base_url}/api/click`, {
        _id: id,
        username: username,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="h-screen bg-gradient-to-r from-indigo-600 to-indigo-500">
      <div className=" flex justify-center align-middle h-screen items-center">
        <div className="card bg-white flex flex-col items-center justify-center p-10 shadow-lg rounded-2xl w-96">
          <div className="profile mx-auto rounded-full py-2 w-90 max-w-xs">
            <img
              src={config.api_base_url + "/avatar/" + username}
              alt="profile image"
              className="rounded-full max-w-[150px]"
            />
          </div>
          <h2 className="text-gray-900 text-4xl mb-1 font-medium title-font text-center">
            {" "}
            <span className="font-bold">{username}</span>{" "}
          </h2>
          <p className="text-gray-600 text-center italic">
            {User ? User.bio : "Loading..."}
          </p>
          {User ? (
            User.links.map((link) => (
              <div className="flex justify-center" key={link._id}>
                <a
                  href={link.url}
                  onClick={() => {
                    addClick(link._id);
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex text-white bg-indigo-500 border-0 py-2 px-16 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-5 hover:drop-shadow-xl ease-in-out lg duration-500"
                >
                  {link.service}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </a>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
}
