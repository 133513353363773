import { React, useEffect, useState } from "react";
import Nav from "./Nav";
import * as axios from "axios";
import { default as config } from "../config.json";
import Footer from "./Footer";
import { decodeToken } from "react-jwt";
import { Link } from "react-router-dom";
export default function UploadAvatar() {
  let isLoggedIn;
  if (localStorage.getItem("token")) {
    isLoggedIn = true;
  }
  const uuid = decodeToken(localStorage.getItem("token")).uuid;
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    axios
      .get(`${config.api_base_url}/api/dashboard/user/${uuid}`)
      .then((res) => setUser(res.data))
      .catch((err) => {
        localStorage.removeItem("token");
        window.location.href = "/login";
      });
  }, [uuid]);
  if (user) {
    console.log(user);
  }

  useEffect(() => {
    const addAvatar = () => {
      if (avatar) {
        let formdata = new FormData();
        formdata.append("image", avatar);
        formdata.append("uuid", uuid);
        axios
          .post(`${config.api_base_url}/api/avatar/upload`, formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            setSuccess(res.data.success);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            setError(err.response.data.error);
          });
      }
    };
    addAvatar();
  }, [avatar]);
  return (
    <>
      <Nav loggedIn={isLoggedIn} />
      <div className="flex justify-center w-full h-screen bg-gray-100">
        <div>
          <div class="bg-white px-4 py-5 rounded-lg shadow-lg text-center w-48">
            <div class="mb-4">
              <img
                class="w-auto mx-auto rounded-full object-cover object-center"
                src={config.api_base_url + "/avatar/" + user.username}
                alt="Avatar Upload"
              />
            </div>
            <label class="cursor-pointer mt-6">
              <span class="inline-flex items-center bg-indigo-500 border-0 py-1 px-3 focus:outline-none hover:bg-indigo-600 rounded-2xl text-base mt-4 md:mt-0 text-white transition ease-in-out">
                Select Avatar
              </span>
              <input
                type="file"
                class="hidden"
                name="image"
                onChange={(e) => {
                  setAvatar(e.target.files[0]);
                }}
                accept="image/*"
              />
            </label>
          </div>
          {success ? (
            <p className="text-green-500 text-xs italic">{success}</p>
          ) : null}
          {error ? (
            <p className="text-red-500 text-xs italic">{error}</p>
          ) : null}

          <Link to="/dashboard">
            <button className="inline-flex transition ease-in-out text-white bg-gray-400 border-0 py-2 px-6 focus:outline-none hover:bg-gray-500 rounded text-lg mt-2 ml-2">
              Go back
            </button>
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
}
