import { React, useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import * as axios from "axios";
import { default as config } from "../config.json";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
export default function Dashboard() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  let loggedIn;
  if (localStorage.getItem("token")) {
    loggedIn = true;
  }
  async function getUser(id) {
    setLoading(true);
    await axios
      .get(`${config.api_base_url}/api/dashboard/user/${id}`)
      .then((res) => setUser(res.data) && setLoading(false))
      .catch((err) => {
        localStorage.removeItem("token");
        window.location.href = "/login";
      });
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const uuid = decodeToken(token);
      if (!uuid) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      getUser(uuid.uuid);
    }
    if (!token) {
      window.location.href = "/login";
    }
  }, []);
  return (
    <>
      <Nav loggedIn={loggedIn} />
      <div className="flex justify-center h-screen items bg-gray-100 ">
        <div>
          <h1 className="text-4xl flex">
            Welcome,{" "}
            <span className="font-bold ">
              {" "}
              {user.username ? (
                user.username
              ) : (
                <p className="w-24 bg-gray-300 h-6 rounded-md mt-3 animate-pulse "></p>
              )}
            </span>
          </h1>

          <p>
            <span className="font-bold">Your Profile URL:</span>{" "}
            <a
              className="hover:cursor-pointer visited:text-indigo-500 select-all "
              onClick={(e) => {
                navigator.clipboard.writeText(
                  `${config.frontend_url}/@${user.username}`
                );
              }}
            >
              {config.frontend_url}/@{user.username}
            </a>
          </p>
          {user.bio ? (
            <p>
              <div className="truncate ...">
                <span className="font-bold">Bio: </span>
                <span className="italic  ">{user.bio}</span>
              </div>
            </p>
          ) : (
            <p className="text-lg">
              You have not added a bio yet. Click the button below to add one.
            </p>
          )}
          <div className="flex gap-2">
            <Link to="/dashboard/add">
              <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-2 transition ease-in-out">
                Manage Links
              </button>
            </Link>
            <Link to="/dashboard/addBio">
              <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-2 transition ease-in-out ">
                Manage Bio
              </button>
            </Link>
            <Link to="/dashboard/addAvatar">
              <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 transition ease-in-out rounded text-lg mt-2">
                Manage Avatar
              </button>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
