import { React, useState } from "react";
import { Link } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import * as axios from "axios";
import { default as config } from "../config.json";
function Register() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  if (localStorage.getItem("token")) {
    window.location.href = "/dashboard";
  }
  async function registerUser() {
    if (password === passwordConfirm) {
      await axios
        .post(`${config.api_base_url}/api/register`, {
          username: username,
          email: email,
          password: password,
        })
        .then(function (response) {
          setError("");
          setSuccess(response.data.success);
          window.location.href = "/login";
        })
        .catch(function (error) {
          setSuccess("");
          setError(error.response.data.error);
        });
    }
  }

  return (
    <div className="h-screen bg-gradient-to-tr from-indigo-600 to-blue-600 ">
      <div className=" flex justify-center align-middle h-screen items-center">
        <div className="lg:w-1/4  bg-gray-100 rounded-lg p-8 w-full shadow-md m-auto">
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Register to{" "}
            <span className="link link-underline link-underline-black">
              Some<span className="font-mono font-bold">I</span>
            </span>{" "}
          </h2>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="username"
              className="leading-7 text-sm text-gray-600 "
            >
              Username <small>(Hint: This will be your custom url)</small>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password"
              className="leading-7 text-sm text-gray-600"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password2"
              className="leading-7 text-sm text-gray-600"
            >
              Repeat Password
            </label>
            <input
              type="password"
              id="password2"
              name="password2"
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  registerUser();
                }
              }}
            />
          </div>
          {password && <PasswordStrengthBar password={password} />}
          {passwordConfirm && password !== passwordConfirm && (
            <p className="text-red-500 text-sm ">Passwords do not match</p>
          )}
          {success && (
            <p className="text-green-500 text-sm font-bold">{success}</p>
          )}
          {error && <p className="text-red-500 text-sm font-bold">{error}</p>}
          <button
            className="text-white transition ease-in-out bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            onClick={registerUser}
          >
            Register
          </button>
          <p className="text-xs text-gray-500 mt-3">
            Already have an account?{" "}
            <Link to="/login">
              <span className="text-indigo-600 hover:text-indigo-700">
                Login
              </span>
            </Link>
          </p>
          <br />

          <Link to="/">
            <p className="text-xs text-gray-500 hover:text-indigo-600">
              Go Back?
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Register;
