import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Nav from "../components/Nav";
import * as axios from "axios";
import { default as config } from "../config.json";
import Footer from "../components/Footer";
import { decodeToken } from "react-jwt";
import { Link } from "react-router-dom";
export default function AddService() {
  const [service, setService] = useState("");
  const [profileLink, setProfileLink] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState({});

  let isLoggedIn = false;
  if (localStorage.getItem("token")) {
    isLoggedIn = true;
  }
  if (isLoggedIn == false) {
    window.location.href = "/";
  }
  const uuid = decodeToken(localStorage.getItem("token")).uuid;
  async function addLink() {
    await axios
      .post(`${config.api_base_url}/api/dashboard/addLink`, {
        url: profileLink,
        service: service,
        id: uuid,
      })
      .then((res) => {
        setError("");
        setSuccess(res.data.success);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
        console.log(res);
      })
      .catch(function (error) {
        console.log(error);
        setSuccess("");
        setError(error.response.data.error);
      });
  }
  useEffect(() => {
    axios
      .get(`${config.api_base_url}/api/dashboard/user/${uuid}`)
      .then((res) => setUser(res.data));
  }, [success]);
  async function deleteLink(id) {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("uuid", uuid);
    await axios
      .post(`${config.api_base_url}/api/dashboard/deleteLink`, formdata)
      .then((res) => {
        setError("");
        setSuccess(res.data.success);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
        console.log(res);
      });
  }
  return (
    <>
      <Nav loggedIn={isLoggedIn} />
      <div className="flex justify-center w-full h-screen bg-gray-100">
        <div>
          <input
            type="text"
            name="service"
            id="service"
            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            placeholder="Title"
            onChange={(e) => {
              setService(e.target.value);
            }}
          />
          <input
            type="text"
            name="link"
            id="link"
            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2"
            placeholder="Link"
            onChange={(e) => {
              setProfileLink(e.target.value);
            }}
            required
          />
          <button
            className="transition ease-in-out inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-2"
            onClick={addLink}
          >
            Add Link
          </button>
          <Link to="/dashboard">
            <button className=" transition ease-in-out inline-flex text-white bg-gray-400 border-0 py-2 px-6 focus:outline-none hover:bg-gray-500 rounded text-lg mt-2 ml-2">
              Go back
            </button>
          </Link>
          {error && <p className="text-red-500 text-xs italic">{error}</p>}
          {success && (
            <p className="text-green-500 text-xs italic">{success}</p>
          )}
          <h1 className="text-2xl mt-5">Your links</h1>
          <ul className="list-disc flex flex-col"></ul>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 ">
              <thead className="text-xs text-gray-700 uppercas ebg-gray-50  ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Link Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Link
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Clicks
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {user.links ? (
                  user.links.map((link) => (
                    <tr className="bg-white border-b" key={link._id}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                      >
                        {link.service}
                      </th>
                      <td className="px-6 py-4">
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noreferrer"
                          className="truncate"
                        >
                          {link.url}
                        </a>
                      </td>
                      <td className="px-6 py-4">{link.clicks}</td>

                      <td className="px-6 py-4 text-right">
                        <Link
                          to={`/dashboard/link/${link._id}`}
                          className="font-medium text-indigo-600  hover:underline"
                        >
                          Edit
                        </Link>
                      </td>
                      <td className="px-6 py-4 text-right">
                        <span
                          onClick={() => deleteLink(link._id)}
                          className=" font-medium cursor-pointer text-indigo-600 hover:text-indigo-700 hover:underline transition-all ease-in-out"
                        >
                          Delete
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                    >
                      You Have not added any links yet.
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
