import React from "react";
import Profile from "../assets/profile.svg";
import * as scroll from "react-scroll";
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <>
      <section className="text-gray-600 body-font ">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center ">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 ">
              <span className="link link-underline link-underline-black">
                Some<span className="font-mono font-bold">I</span>
              </span>
              <span className="text-gray-400 text-2xl"> (ˈsəmˌwən)</span>
              <br className="hidden lg:inline-block" />
              <span className="italic">Express yourself</span>
            </h1>
            <p className="mb-8 leading-relaxed">
              <span className="link link-underline link-underline-black">
                Somei
              </span>{" "}
              lets you create your personal page, with all of your profile
              links, a biography, profile picture and whatever you can think of!
            </p>
            <div className="flex justify-center">
              <Link to="/register">
                <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg transition ease-in-out">
                  Get Started
                </button>
              </Link>
              <scroll.Link to="features" spy={true} smooth={true}>
                <button className="transition ease-in-out ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
                  Features
                </button>
              </scroll.Link>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/3 w-5/12">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={Profile}
            />
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font " id="features">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
              How{" "}
              <span className="link link-underline link-underline-black">
                Some<span className="font-mono font-bold">I</span>
              </span>{" "}
              can help you!
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
              By creating a beautiful personal page on our platform, you can
              display all of your links in one central location.
            </p>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 w-9"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Analytics
                </h2>
                <p className="leading-relaxed text-base">
                  Get analytics about your page.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 w-9"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Donations
                </h2>
                <p className="leading-relaxed text-base">
                  We allow you to accept donations on your page, either via
                  various crypto currencies or PayPal.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 w-9"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Customization
                </h2>
                <p className="leading-relaxed text-base">
                  You can customize your personal page as much as you want. With
                  a custom profile picture (GIFs as well!), a custom biography,
                  profile links and more!
                </p>
              </div>
            </div>
          </div>
          <Link to="/register">
            <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg hover:scale-125 ease-out duration-300 ">
              Register Now
            </button>
          </Link>
        </div>
      </section>
    </>
  );
}
