import './index.css';
import Home from './Home';
import Login from './routes/Login';
import Register from './routes/Register';
import Dashboard from './routes/Dashboard';
import AddService from './components/AddService';
import Logout from './routes/Logout';
import Profile from './routes/Profile';
import AddBio from './components/AddBio';
import LinkPage from './components/LinkPage';
import UploadAvatar from './components/UploadAvatar'
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/dashboard/add" element={<AddService />} />
      <Route path="/@:username" element={<Profile />} />
      <Route path="/dashboard/addbio" element={<AddBio />} />
      <Route path="/dashboard/link/:id" element={<LinkPage />} />
      <Route path="/dashboard/addAvatar" element={<UploadAvatar />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);