import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
export default function Logout() {
  let loggedIn;
  if (localStorage.getItem("token")) {
    loggedIn = true;
  }
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  function logout() {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
  return (
    <>
      <Nav loggedIn={loggedIn} />
      <div className="flex justify-center align-middle h-screen items text-center">
        <div className="container">
          <h1 className="text-lg">Are you sure you want to log out?</h1>
          <button
            className="transition ease-in-out text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
