import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
function Home() {
  let loggedIn;
  if (localStorage.getItem("token")) {
    loggedIn = true;
  }
  return (

    <div className="App">
      
      <Nav loggedIn={loggedIn} />
      <Hero />
      <Footer />
    </div>
  );
}

export default Home;
