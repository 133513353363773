import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Nav from "./Nav";
import * as axios from "axios";
import { default as config } from "../config.json";
import Footer from "./Footer";
import { decodeToken } from "react-jwt";
import { Link } from "react-router-dom";
export default function LinkPage() {
  const { id } = useParams();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  let isLoggedIn;
  if (localStorage.getItem("token")) {
    isLoggedIn = true;
  }
  const uuid = decodeToken(localStorage.getItem("token")).uuid;
  async function editLink() {
    if (link && title) {
      await axios
        .post(`${config.api_base_url}/api/dashboard/editLink`, {
          link: link,
          title: title,
          id: id,
          uuid: uuid,
        })
        .then((res) => {
          setError("");
          setSuccess(res.data.success);
          console.log(res);
        })
        .catch(function (error) {
          console.log(error);
          setSuccess("");
          setError(error.response.data.error);
        });
    } else {
      setError("Please fill all fields");
    }
  }
  async function getOldLink() {
    await axios
      .post(`${config.api_base_url}/api/dashboard/link/${id}`, {
        uuid: uuid,
        id: id,
      })
      .then((res) => {
        console.log(res.data);
        setLink(res.data.url);
        setTitle(res.data.title);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getOldLink();
  }, []);

  return (
    <>
      <Nav loggedIn={isLoggedIn} />
      <div className="flex justify-center w-full h-screen bg-gray-100">
        <div>
          <input
            type="text"
            name="title"
            id="title"
            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2"
            defaultValue={title}
            placeholder={title ? title : "Title"}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            required
          />
          <input
            type="text"
            name="link"
            id="link"
            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2"
            defaultValue={link}
            placeholder={link ? link : "Link"}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            required
          />
          <button
            className="inline-flex transition ease-in-out text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-2"
            onClick={editLink}
          >
            Edit Link
          </button>
          <Link to="/dashboard/add">
            <button className="inline-flex transition ease-in-out text-white bg-gray-400 border-0 py-2 px-6 focus:outline-none hover:bg-gray-500 rounded text-lg mt-2 ml-2">
              Go back
            </button>
          </Link>
          {error && <p className="text-red-500 text-xs italic">{error}</p>}
          {success && (
            <p className="text-green-500 text-xs italic">{success}</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
